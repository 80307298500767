.bgTrace {
  background-color: rgba(255, 106, 0, 0.04);
}

.fontAlert {
  color: red;
}

.dx-button {
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff;
  color: #002c6f;
  box-shadow: 0 1px 3px rgba(0,0,0,.24);
}